export enum PlaidProduct {
  Assets = 'assets',
}
export type PlaidProductType = PlaidProduct;

export interface PlaidSettings {
  plaidPublicKey: string;
  env: string;
  clientName?: string;
  product: PlaidProductType[];
}

export interface GrafanaFaroSettings {
  url: string;
  app: {
    name: string;
    environment: string;
  };
}

export interface Environment {
  production: boolean;
  showCaptcha: boolean;
  apiBaseUrl: string;
  plaidSettings: PlaidSettings;
  grafanaFaroSettings: GrafanaFaroSettings;
  captchaKey: string;
  googleId: string | null;
  segmentApiKey: string;
  pid_test?: number;
  main_page: string;
}

export const PLAID_SETTINGS: PlaidSettings = {
  plaidPublicKey: '031e579e890d8e57a9828cf49b9370',
  clientName: 'Fundo',
  env: 'sandbox',
  product: [PlaidProduct.Assets],
};
